import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ThemeProvider } from "styled-components";
import client from "utils/http-client";
import { ToastContainer } from "react-toastify";
import { ReactQueryDevtools } from "react-query/devtools";
import qs from "query-string";
import lightTheme from "./theme.light";
import App from "./App";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      retry: false,
    },
  },
});

const run = async () => {
  const query = qs.parse(document.location.search);
  if (query.access_token) {
    localStorage.setItem("access_token", query.access_token);
  }
  // Login
  const token = localStorage.getItem("access_token");
  if (token) {
    await client.get("/api/token").catch(() => {
      localStorage.removeItem("access_token");
    });
  }

  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={lightTheme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ToastContainer
              autoClose={6000}
              closeOnClick
              hideProgressBar
              position="bottom-right"
            />
            <App />
            <ReactQueryDevtools />
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

run();
