import React from "react";
import { Navigate } from "react-router-dom";
import OnboardingIntro from "pages/onboarding/intro";
import OnboardingProvider from "pages/onboarding/provider";
import OrdersIndex from "pages/orders";
import OrderIdPage from "pages/orders/orderId";

const routes = ({ isLoggedIn }) => {
  const protect = (Component) =>
    isLoggedIn ? Component : <Navigate to="/onboarding" />;

  return [
    {
      path: "/",
      element: <Navigate to={isLoggedIn ? "/orders" : "/onboarding"} />,
    },
    // Onboarding
    {
      path: "/onboarding",
      children: [
        {
          path: "",
          element: <Navigate to="intro" />,
        },
        {
          path: "intro",
          element: <OnboardingIntro />,
        },
        {
          path: "provider",
          element: <OnboardingProvider />,
        },
      ],
    },
    {
      path: "/orders",
      children: [
        {
          path: "",
          element: protect(<OrdersIndex />),
        },
        {
          path: ":orderId",
          element: protect(<OrderIdPage />),
        },
      ],
    },
    // {
    //   // Not Found
    //   path: "*",
    //   element: isLoggedIn ? (
    //     <Navigate to="templates" />
    //   ) : (
    //     <Navigate to="login" />
    //   ),
    // },
  ];
};

export default routes;
