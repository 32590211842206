import React from "react";
import { Container } from "components/Styled";
import OrderItem from "components/OrderItem";
import { useQuery } from "react-query";
import client from "utils/http-client";
import Flex from "@react-css/flex";
import AppLayout from "components/AppLayout";

function TrackingIndex() {
  const orders = useQuery("orders", () => client.get("/api/shipments"), {
    placeholderData: [],
  });

  return (
    <AppLayout>
      <Container style={{ paddingTop: 30 }}>
        <Flex alignItemsCenter justifySpaceBetween>
          <h1>Shipments</h1>
        </Flex>
        <div style={{ margin: "10px 0" }}>
          {orders.isFetching && <div>Loading...</div>}
          {orders.isError && <div>Error Loading Data</div>}
        </div>
        {orders.data &&
          orders.data.map((item, index) => (
            <OrderItem key={index} item={item} />
          ))}
      </Container>
    </AppLayout>
  );
}

export default TrackingIndex;
