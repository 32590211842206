import React from "react";
import get from "lodash/get";
import Flex from "@react-css/flex";
import { Status } from "components/Styled";

export default function TrackingItem({ tracking, status }) {
  const info = get(tracking, "data.tracking[0]");
  let domain = null;
  if (info) domain = new URL(info.tracking_link);

  return (
    <Flex style={{ marginTop: 20 }}>
      {info && (
        <>
          <div style={{ marginRight: 20 }}>
            <img
              src={`https://logo.clearbit.com/${domain.host}`}
              style={{ width: 46 }}
            />
          </div>
          <div>
            <strong>{info.carrier_name}</strong> <br />
            <a
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              href={info.tracking_link}
              rel="noreferrer"
            >
              {info.tracking_number || "N/A"}
            </a>
          </div>
        </>
      )}
      {!info && <>No Tracking data yet.</>}
      <Flex style={{ flexGrow: 1 }} justifyEnd>
        <div>
          <Status type={status}>{status.split("_").join(" ")}</Status>
        </div>
      </Flex>
    </Flex>
  );
}
