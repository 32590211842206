import React from "react";
import { Button, Container } from "components/Styled";
import Flex from "@react-css/flex";
import styled from "styled-components";
import { ReactComponent as Pin } from "assets/icons/pin.svg";
import { ReactComponent as Box } from "assets/icons/box.svg";
import { ReactComponent as Shield } from "assets/icons/shield.svg";
import { Link } from "react-router-dom";

const IconWrapper = styled.div`
  margin-left: -13px;
`;

const nodes = [
  {
    title: "Automatic tracking",
    text: "Track every order with real-time push notifications and delivery time estimates.",
    icon: <Pin />,
  },
  {
    title: "All in one place",
    text: "Package and order details fetched from many sources that you can keep track of in one place.",
    icon: <Box />,
  },
  {
    title: "Privacy first",
    text: "We’ll only look for packages in the last 50 days. Nothing else.",
    icon: <Shield />,
  },
];

function OnboardingIntro() {
  return (
    <Container>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyCenter
        style={{ minHeight: "100vh" }}
      >
        <h1>Link your email and track all your packages</h1>
        {nodes.map((node) => (
          <Flex key={node.title} alignItemsCenter justifyStart>
            <IconWrapper>{node.icon}</IconWrapper>
            <Flex flexDirection="column">
              <h2>{node.title}</h2>
              <div>{node.text}</div>
            </Flex>
          </Flex>
        ))}
        <Link
          to="../provider"
          style={{ width: "100%", display: "block", marginTop: 30 }}
        >
          <Button style={{ width: "100%" }}>Next</Button>
        </Link>
      </Flex>
    </Container>
  );
}

export default OnboardingIntro;
