import React from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "components/Styled";
import Flex from "@react-css/flex";
import LogoImage from "./shoprunner_logo.png";

const Root = styled.header`
  padding: 5px 15px;
  background #fff;
  border-bottom: 1px solid #eee;
  a {
    border: none;
  }
`;

const Logo = styled.img`
  width: 160px;
`;

export default function Header() {
  const nav = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    nav("/");
  };
  return (
    <Root>
      <Flex alignItemsCenter justifySpaceBetween>
        <Link to="/orders">
          <Logo src={LogoImage} alt="" />
        </Link>
        <Button size="small" onClick={handleLogout}>
          Logout
        </Button>
      </Flex>
    </Root>
  );
}
