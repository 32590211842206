import styled from "styled-components";

export const Button = styled.button`
  padding: 10px 18px;
  font-size: 16px;
  background: ${(p) => p.theme.primary};
  color: white;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  transition: color 0.3s, background 0.2s;
  &:hover {
    background: ${(p) => p.theme.primaryLight};
  }
  border-radius: 3px;

  ${(p) =>
    p.size === "small" &&
    `
  padding: 5px 16px;
  font-size: 14px;
  `}

  ${(p) =>
    p.size === "square" &&
    `
  padding:0;
  width: 33px;
  line-height: 33px;
  font-size: 14px;
  `}

  ${(p) =>
    p.type === "blank" &&
    `
    background: none;
    color: ${p.theme.text};
    border: 1px solid ${p.theme.text};
    color: ${p.theme.text};
    &:hover {
      background: none;
      border: 1px solid ${p.theme.secondary};
      color: ${p.theme.secondary};
    }
  `}

  ${(p) =>
    p.type === "danger" &&
    `
    background: none;
    color: ${p.theme.danger};
    border: 1px solid ${p.theme.danger};
    color: ${p.theme.danger};
    &:hover {
      background: none;
      border: 1px solid ${p.theme.dangerLight};
      color: ${p.theme.dangerLight};
    }
  `}
`;

export const Table = styled.table`
  border-spacing: 0;
  thead {
    text-align: left;
    th {
      color: #6a7285;
      font-weight: 500;
      padding: 10px;
      border-bottom: 3px solid #f7f7f8;
    }
  }
  td {
    padding: 10px;
  }
`;

export const Input = styled.input`
  padding: 12px 10px;
  outline: 1px solid #8d94a5;
  border: none;
  width: 100%;
`;

export const InputGroup = styled.div`
  margin: 20px 0;
`;

export const Label = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 3px;
`;

export const Container = styled.div`
  max-width: 720px;
  margin: auto;
  padding: 0 20px;
`;

export const Status = styled.div`
  padding: 2px 10px;
  border-radius: 3px;
  color: white;
  font-size: 13px;
  background: #337ab7;
  ${(p) =>
    p.type === "order_delivered" &&
    `
    background: #5cb85c;
  `}
  ${(p) =>
    p.type === "order_confirmed" &&
    `
    background: #5bc0de;
  `}
`;
