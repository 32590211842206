import React from "react";
import { useParams } from "react-router-dom";
import client from "utils/http-client";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Container, Button } from "components/Styled";
import Flex from "@react-css/flex";
import AppLayout from "components/AppLayout";
import styled from "styled-components";
import TrackingItem from "components/TrackingItem";

const Divider = styled.hr`
  border: none;
  margin: 30px 0;
  border-bottom: 1px solid #eee;
`;

const MerchantLogo = styled.img`
  width: 50px;
  border-radius: 8px;
`;

const Total = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export default function OrderIdPage() {
  const { orderId } = useParams();
  const qc = useQueryClient();
  const { data: order } = useQuery(["orders", orderId], () =>
    client.get(`/api/shipments/${orderId}`)
  );
  const mutation = useMutation(
    () =>
      client.put(`/api/shipments/${order._id}`, {
        _status: "order_delivered",
      }),
    {
      onSuccess: () => {
        qc.invalidateQueries("orders");
      },
    }
  );

  const formatPrice = (price) => {
    const num = price ? price / 100 : 0;
    const formatted = num.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return `$${formatted}`;
  };

  const markAsDelivered = () => mutation.mutate();

  if (!order) return null;

  return (
    <AppLayout>
      <Container style={{ marginTop: 50 }}>
        {/* <Flex>
          <Link to="/orders">
            <Button size="square">
              <BiArrowBack />
            </Button>
          </Link>
        </Flex> */}
        <Flex style={{ marginBottom: 20 }} alignItemsCenter>
          <MerchantLogo
            src={`https://logo.clearbit.com/${order.data.sender_domain}`}
            alt=""
          />
          <h1 style={{ marginLeft: "10px" }}>{order.data.merchant_name}</h1>
        </Flex>
        <TrackingItem tracking={order.tracking[0]} status={order._status} />
        <Flex justifyEnd style={{ marginTop: 10 }}>
          {order._status !== "order_delivered" && (
            <Button size="small" onClick={markAsDelivered}>
              Mark as Delivered
            </Button>
          )}
        </Flex>
        <Divider />

        {order.data.products.map((product, idx) => (
          <Flex alignItemsCenter justifySpaceBetween key={idx}>
            <div>
              <img
                src={product.image_url}
                style={{ width: 160, marginRight: 30 }}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <h4 style={{ marginBottom: 10 }}>{product.name}</h4>
              <Flex alignItemsStart justifySpaceBetween>
                <div>
                  Color: {product.color} <br />
                  Size: {product.size} <br />
                  Qty: {product.quantity} <br />
                </div>
                <strong>{formatPrice(product.unit_price)}</strong>
              </Flex>
            </div>
          </Flex>
        ))}
        <Divider />
        <Flex alignItemsCenter justifySpaceBetween>
          Shipping:
          <div>{formatPrice(order.data.shipping_total)}</div>
        </Flex>
        <Flex alignItemsCenter justifySpaceBetween style={{ marginTop: 10 }}>
          Tax:
          <div>{formatPrice(order.data.tax_total)}</div>
        </Flex>
        <Flex alignItemsCenter justifySpaceBetween style={{ marginTop: 10 }}>
          Total:
          <Total>{formatPrice(order.data.order_total)}</Total>
        </Flex>
      </Container>
    </AppLayout>
  );
}
