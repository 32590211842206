import React from "react";
import { Container } from "components/Styled";
import Flex from "@react-css/flex";
import styled from "styled-components";
import { ReactComponent as GmailIcon } from "assets/logo/gmail.svg";

const Pane = styled(Flex)`
  display: block;
  cursor: pointer;
`;

function OnboardingProvider() {
  const handleClick = () => {
    document.location = `${process.env.REACT_APP_API_URL || ""}/auth/nylas`;
  };
  return (
    <Container>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyCenter
        style={{ minHeight: "100vh" }}
      >
        <h1>Select your email provider</h1>
        <Pane
          onClick={handleClick}
          alignItems="center"
          justifyContent="center"
          style={{ width: "100%", marginTop: 30 }}
        >
          <img src="/signin.png" alt="" />
        </Pane>
      </Flex>
    </Container>
  );
}

export default OnboardingProvider;
