import React from "react";
import { useRoutes } from "react-router-dom";
import { useQuery } from "react-query";
import client from "utils/http-client";

import routes from "./routes";

function App() {
  const check = useQuery("status", () => client.get("/api"));
  const isLoggedIn = localStorage.getItem("access_token");
  const routing = useRoutes(routes({ isLoggedIn }));
  if (check.status === "loading")
    return (
      <center style={{ padding: 10 }}>
        <h1>Loading</h1>
        Please wait... <br />
        <small>(may take up to 20seconds to boot the app)</small>
      </center>
    );
  return <div className="app">{routing}</div>;
}

export default App;
