import React from "react";
import styled from "styled-components";
import Header from "components/Header";

const Root = styled.div`
  min-height: 100vh;
`;
//  background: ${(p) => p.theme.background};

export default function AppLayout({ children }) {
  return (
    <Root>
      <Header />
      <main>{children}</main>
    </Root>
  );
}
