import axios from "axios";

const client = axios.create({
  timeout: 120000,
  baseURL: process.env.REACT_APP_API_URL || "",
});

client.interceptors.request.use((request) => {
  request.headers.authorization = `Bearer ${localStorage.getItem(
    "access_token"
  )}`;
  return request;
});

client.interceptors.response.use(
  (response) =>
    // Format response
    response.data,
  (error) =>
    // Format Error
    Promise.reject(error)
);

export default client;
