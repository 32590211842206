// Use this file when the API is ready

import React from "react";
import styled from "styled-components";
import Flex from "@react-css/flex";
import { useImage } from "react-image";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TrackingItem from "components/TrackingItem";
import { get } from "lodash";

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 20px 15px;
  margin: 10px 0;
  cursor: pointer;
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
`;

const Logo = styled(Flex)`
  margin-right: 15px;
`;

const MerchantLogo = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 1px;
  background #eee;
  margin-top: 0;
  border-radius: 3px;
`;

const MessageDate = styled.div`
  font-size: 13px;
  opacity: 0.8;
`;

function OrderItem({ item }) {
  const nav = useNavigate();
  const { data } = item || { data: {} };

  const { src } = useImage({
    srcList: [`https://logo.clearbit.com/${data.sender_domain}`],
    useSuspense: false,
  });
  const go = () => nav(`/orders/${item._id}`);
  return (
    <Card onClick={go}>
      <Flex alignItemsStart>
        {/* Logo */}
        <Logo>
          <MerchantLogo src={src} />
        </Logo>

        {/* Content */}
        <Flex.Item grow={1}>
          <Flex
            alignItemsCenter
            justifySpaceBetween
            style={{ marginBottom: 20 }}
          >
            <div>
              <h2>{data.merchant_name}</h2>
              <small>#{data.order_id}</small>
            </div>
            <Flex alignItemsCenter justifyEnd>
              <MessageDate>
                {moment(data.purchase_date, "X").fromNow()}
              </MessageDate>
            </Flex>
          </Flex>
          <Flex alignItemsCenter>
            {data.products &&
              data.products.map((line, idx) => (
                <div style={{ marginRight: 10 }} key={idx}>
                  <img src={line.image_url} style={{ width: 60 }} alt="" />
                </div>
              ))}
          </Flex>
          <TrackingItem
            tracking={get(item, "tracking[0]")}
            status={item._status}
          />
        </Flex.Item>
      </Flex>
    </Card>
  );
}

export default OrderItem;
